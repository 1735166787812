import React from 'react'
import Link from 'gatsby-link'
import { Tooltip } from '@material-ui/core'
import ImplantIcon from 'components/icons/Implant'
import BondingIcon from 'components/icons/Bonding'
import CrownIcon from 'components/icons/Crown'
import BridgesIcon from 'components/icons/Bridges'
import VeneerIcon from 'components/icons/Veneer'
import DenturesIcon from 'components/icons/Dentures'
import XRayIcon from 'components/icons/XRay'
import FillingIcon from 'components/icons/Filling'
import HygieneIcon from 'components/icons/Hygiene'
import PeriodontalIcon from 'components/icons/Periodontal'
import WisdomTeethIcon from 'components/icons/WisdomTeeth'
import WhiteningIcon from 'components/icons/Whitening'
import RootCanalIcon from 'components/icons/RootCanal'
import dentalKit from 'static/dental-kit.jpg'

const TreatmentIconContainer = props => (
  <div
    className="bg-offWhite mt-4 mr-1 self-center flex items-center justify-center w-16 h-16 rounded-full"
    {...props}
  />
)

const TreatmentsSection = () => (
  <div className="my-5 py-20 px-4 bg-white text">
    <div className="container mx-auto px-5">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 pt-4 pr-12 pb-12 text-center lg:text-left">
          <h1 className="text-4xl tracking-tight text-open-blue mt-0">
            Service We Offer
          </h1>
          <p className="text-lg">
            Learn about dental treatments we provide a variety of dental
            procedures that you may want to learn more about.
          </p>
          <div className="flex flex-wrap">
            <Tooltip title="Bonding" aria-label="Bonding">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <BondingIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Implant" aria-label="Implant">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <ImplantIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Crown" aria-label="Crown">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <CrownIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Bridges" aria-label="Bridges">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <BridgesIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Veneer" aria-label="Veneer">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <VeneerIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Dentures" aria-label="Dentures">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <DenturesIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="X-Ray" aria-label="X-Ray">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <XRayIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Filling" aria-label="Filling">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <FillingIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Hygiene" aria-label="Hygiene">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <HygieneIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Periodontal" aria-label="Periodontal">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <PeriodontalIcon
                    size="2rem"
                    color="mainBlue"
                    fill="mainBlue"
                  />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Wisdom Teeth" aria-label="Wisdom Teeth">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <WisdomTeethIcon
                    size="2rem"
                    color="mainBlue"
                    fill="mainBlue"
                  />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Whitening" aria-label="Whitening">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <WhiteningIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
            <Tooltip title="Root Canal" aria-label="Root Canal">
              <Link to="/treatments">
                <TreatmentIconContainer color="offWhite">
                  <RootCanalIcon size="2rem" color="mainBlue" fill="mainBlue" />
                </TreatmentIconContainer>
              </Link>
            </Tooltip>
          </div>
        </div>
        <img className="flex-1 rounded" src={dentalKit} alt="Dental Kit" />
      </div>
    </div>
  </div>
)

export default TreatmentsSection
